<template>
  <div class="loader loader-spin">
    <div class="loader-body">
      <div class="loader-image">
	      <div data-loader="circle-scale"></div>
      </div>
    </div>
    <div class="loader-overlay"></div>
  </div>
</template>

<style lang="scss">
@import 'styles';
</style>
