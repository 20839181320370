import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { BASE_ROUTES, META_CONFIG } from './const';
import { useRoute } from 'vue-router';
export function useHeadSettings() {
    const route = useRoute();
    const routeNameForMeta = computed(() => {
        return route.name === BASE_ROUTES.docs ? route.params?.type : route.name;
    });
    const headTitle = computed(() => META_CONFIG[routeNameForMeta.value]?.title || META_CONFIG.home?.title);
    const headDescription = computed(() => META_CONFIG[routeNameForMeta.value]?.description || META_CONFIG.home?.description);
    useHead({
        title: headTitle,
        meta: [
            {
                name: 'description',
                content: headDescription
            }, {
                property: 'og:locale',
                content: 'ru_RU'
            }, {
                property: 'og:type',
                content: 'website'
            }, {
                property: 'og:title',
                content: 'Купить робуксы Роблокс дёшево на RbxTree.io'
            }, {
                property: 'og:description',
                content: 'Покупай робуксы удобно, моментально и безопасно. Много положительных отзывов реальных клиентов! Следи за акциями Дерева Робуксов!'
            }, {
                property: 'og:url',
                content: 'https://rbxtree.io/'
            }, {
                property: 'og:image',
                content: '/img/logo.39eaa367.svg'
            }, {
                property: 'og:site_name',
                content: 'Интернет-магазин робуксов'
            }
        ]
    });
}
